.support {
  height: fit-content;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  opacity: 1;

  .support-menu {
    width: 100%;
    height: 100%;
    background-image: url("../../../img/Default_Rectangle_Tile.png");
    background-size: 100px 100px;

    .gradient {
      height: 100%;
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background: transparent
        linear-gradient(0deg, #011e41 -30%, #011e4100 100%) 0% 0% no-repeat
        padding-box;

      .title {
        color: var(--content-text-foreground-colour);
        margin-top: 5%;
      }

      .tiles {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        grid-gap: 46px;
        margin-top: 5%;

        .tile {
          color: var(--content-text-foreground-colour);
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: var(--tertiary-background-colour);
          width: 240px;
          height: 160px;
          border: 2px solid var(--tertiary-background-colour);
          border-radius: 6px;

          .tile__icon {
            font-size: 45px;
          }

          .tile__text {
            margin-top: 5px;
            font-size: 20px;
          }

          &:hover {
            border-color: var(--content-text-foreground-colour);
          }
        }
      }
    }
    .breadcrumb {
      padding: 8px;
    }
  }

  .navigation-directory {
    display: grid;
    height: 60px;
    width: 95%;

    .navigation-list {
      display: flex;
      justify-items: left;
      align-items: center;
      justify-content: left;
      font-size: 18px;

      .navigation-link {
        display: flex;
        align-items: center;

        .navigation-link-clickable {
          text-decoration: none;
          color: var(--content-text-foreground-colour);

          &:hover {
            text-decoration: underline;
          }
        }
        .navigation-spacer {
          margin-left: 8px;
          margin-right: 8px;
        }
      }
    }
  }

  @import "./support/Glossary.scss";
  @import "./support/ContactUs.scss";
  @import "./support/GetStarted.scss";
  @import "./support/QuickStartGuide.scss";
  @import "./support/ReleaseNotes.scss";
}

.breadcrumb {
  padding: 8px;
}
