.themevariant {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.themevariant.themevariant--selected {
  background-color: var(--themevariant--selected-background-colour);
  color: var(--themevariant--selected-foreground-colour);
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid var(--themevariant--selected-foreground-colour);
}