.main-content {
  display: flex;
  flex: 1;
  flex-direction: row;

  .grid-selection-text {
   margin: 20px;
   font-size: 1.5rem;
  }

  .app-body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: var(--sidenav-expanded-width);
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--appbody-background-colour);

    .app-content {
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      background-size: 100px 100px;

      .grid-parent {
        display: flex;
        flex-direction: column;
        flex: 1;

        .active-filter-wrapper {
          margin: 5px 15px;
          min-height: 2rem;
          .chip-wrapper {
            margin-right: 10px;
          }
        }
      }
    }

    @import './content/Applications.scss';
    @import './content/Home.scss';
    @import './content/News.scss';
    @import './content/Support.scss';
  }

  .app-body.expanded {
    margin-left: var(--sidenav-width);
  }
}

// Layout
@import './layout/Sidebar.scss';
@import './layout/navbar/Navbar.scss';
@import './layout/sidenav/Sidenav.scss';
