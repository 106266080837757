.tile {
  color: var(--secondary-background-colour);
  background-color: var(--tertiary-background-colour);
  border: 2px solid var(--tertiary-background-colour);
  border-radius: 2px;
  box-shadow: 0 3px 6px #00000029;
  max-width: 1250px;
  padding: 20px 25px;
  margin: 10px 0;
  cursor: pointer;

  .summary {
    display: flex;
    align-items: center;
    flex-grow: 1;

    .icon {
      flex-shrink: 0;
      font-size: 46px;
      justify-self: center;
      align-self: center;

      &.closed {
        color: var(--senary-foreground-colour);
      }

      &.open {
        color: var(--secondary-background-colour);
      }
    }

    .date {
      flex-shrink: 0;
      color: #a4a4a4;
      margin-left: 24px;
      margin-right: 24px; // Note that this breaks convention of components positioning themselves only because we need
      // .details to be either in the third child or first child for news and glossary respectively
    }

    .details {
      flex-grow: 1;

      .title {
        font-weight: bold;
      }
    }

    .extendButton {
      margin-left: 24px;
      flex-shrink: 0;
      height: 32px;
      width: 136px;
      border: 1px solid var(--secondary-background-colour);
      border-radius: 4px;
      cursor: pointer;
      padding-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .tile-closed {
        font-size: 22px;
        transition: all 0.5s;
        transform: rotate(0);
      }

      .tile-open {
        font-size: 22px;
        transition: all 0.5s;
        transform: rotate(90deg);
      }
    }
  }
  .description {
    display: block;
    overflow:hidden;

    &.open {
      padding-top: 10px;
      margin-top: 15px;
      border-top: 1px;
      border-top-style: solid;
      border-color: #a4a4a4;
      
      transition:transform 0.3s ease-out; // note that we're transitioning transform, not height!
      transform:scaleY(1); // implicit, but good to specify explicitly
      transform-origin:top; // keep the top of the element in the same place. this is optional.
    }

    &.closed {
      height: 0;
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
      
      transition:transform 0.3s ease-out; // note that we're transitioning transform, not height!
      transform:scaleY(0); // implicit, but good to specify explicitly
      transform-origin:top; // keep the top of the element in the same place. this is optional.
    }
  }

  &:hover {
    border: 2px solid var(--secondary-background-colour);

    .icon {
      &.closed {
        color: var(--secondary-background-colour);
      }
    }
  }
}