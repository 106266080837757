.insights-app.dark,
.dark-colours {
  --appcontent-background-colour: var(--primary-background-colour);
  --nav-background-colour: var(--secondary-background-colour);
  --footer-background-colour: var(--secondary-background-colour);
  --themevariant--selected-background-colour: var(--tertiary-background-colour);
  --themevariant--selected-foreground-colour: var(--secondary-background-colour);
  --nav-icon-text-colour: var(--quarternary-foreground-colour);
  --nav-text-colour: var(--quarternary-foreground-colour);
  --themechanger-foreground-colour: var(--primary-foreground-colour);
}