.viewlist {
  flex: 1;

  animation-name: hideList;
  animation-duration: 2s;

  @keyframes hideList {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.viewlist.viewlist--expanded {
  animation-name: showList;
  animation-duration: 2s;

  @keyframes showList {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}


.viewcategory {
  min-height: 48px;
  display: flex;
  flex-direction: column;
  transition: var(--colors-transition);
  text-decoration: none;
  cursor: pointer;

  .viewcategory__category,
  .view {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--sidenav-link-text-colour);
    border-top: 1px solid var(--senary-foreground-colour);
  }

  .viewcategory__category {
    min-height: 48px;
    font-size: 12px;

    .viewcategory__icon {
      width: 12px;
      margin-left: 25px;
    }

    .viewcategory__name {
      margin-left: 18px;
    }

    .viewcategory__chevron {
      transform: rotate(90deg);
      margin-left: auto;
      margin-right: 15px;
    }
  }

  .view {
    color: var(--sidenav-link-text-colour);
    border-top: 1px solid var(--senary-foreground-colour);
    min-height: 24px;
    font-size: 10px;

    .view__handle {
      margin-left: 55px;
    }
  }

  .view.view--active {
    color: white;

    .view__chevron {
      font-size: 7px;
      width: 10px;
      margin-left: 25px;
    }

    .view__handle {
      margin-left: 20px;
    }
  }
}

.viewcategory:last-child {
  border-bottom: 1px solid var(--senary-foreground-colour);
  transition: var(--colors-transition);
}

.viewcategory.viewcategory--open,
.viewcategory:hover {
  background-color: var(--sidenav-link-background-active-colour);
}

.viewcategory.viewcategory--open {
  .viewcategory__category {
    color: var(--sidenav-link-text-active-colour);
  }
}

.viewcategory:hover {
  color: var(--sidenav-link-text-hover-colour);
}
