.user {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 72px;

  .user__photo {
    width: 34px;
    min-width: 34px;
    height: 34px;
    border-radius: 17px;
    background-size: contain;
    background-color: white;
  }  

  .user__details {
    color: var(--sidenav-user-text-colour);
    display:flex;
    overflow: hidden;
    flex-direction: column;
    animation-name: showDetail;
    animation-duration: 2s;

    .user__name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
  
    .user__title {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 3px;
      font-size: 10px;
    }

    @keyframes showDetail {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
}
