.logo {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--sidenav__logo-background-colour);
  transition: var(--colors-transition);

  .logo__logo { 
    flex-grow: 1;
    display: flex;
    justify-content: center;
    .innerlogo {
      height: 37px;
      width: 32px;
      display: block;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 22.84 33.87">\
        <path fill="white" d="M11,0a1.75,1.75,0,0,0-1.6,1.68V5.45H0V7.63H9.39v22l2.52,4.27,2.53-4.27v-22h8.4V5.45H14.41v-2A.59.59,0,0,1,15,2.91h7.74V0H11.07"/>\
      </svg>');
    }
  }

  .logo__toggle {
    margin-right: -8px;
    display: flex;
    background-color: var(--nav-toggle-background-colour);
    color: var(--nav-toggle-icon-text-colour);
    transition: var(--colors-transition);
    width: 14px;
    height: 14px;
    border: 1px solid var(--nav-toggle-icon-text-colour);
    border-radius: 7px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 3px #00000029;
  }
}
