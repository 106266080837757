.sub-content.get-started {
  display: flex;
  align-items: normal;
  flex-direction: column;
  flex-grow: 1;
  white-space: normal;
  max-width: 50%;
  min-width: 500px;

  .text-doc {
    color: var(--secondary-background-colour);
    background-color: var(--tertiary-background-colour);
    margin: 0 30px 0 30px;
    border-radius: 2px;
    box-shadow: 0 3px 6px #00000029;
    padding: 12px 30px;
    height: 100%;

    .title {
      text-align: left;
      font: normal normal 600 18px/22px Segoe UI;
      letter-spacing: 0px;
      color: #0b1b40;
      opacity: 1;
    }

    .quickStartLink {
      display: flex;
      align-items: center;
      margin: 10px 0;

      .icon {
        font-size: 38px;
        margin-right: 12px;
      }
    }

    p {
      text-align: left;
      font: normal normal normal 16px/24px Segoe UI;
      letter-spacing: 0px;
    }

    a {
      color: blue;
    }
  }
}
