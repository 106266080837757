.sub-content.glossary, .sub-content.glossaryadmin {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 95%;
  align-items: center;

  .inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 1304px;
    width: 100%;

    .toc {
      display: flex;
      flex-direction: column;
    }

    @import '../../components/Tile.scss';

    .tile {
      cursor: default;
    }
    

    .images {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .image {
        flex-grow: 0;
        flex-shrink: 0;
        width: 50%;
        min-width: 250px;
        max-width: 500px;
      }
    }
  }
}
