.sidenav {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: var(--sidenav-background-colour);
  transition: var(--colors-transition), width 0.5s;
  z-index: var(--sidenav-layer-zindex);
  width: var(--sidenav-width);
  display: flex;
  flex-direction: column;
  color: var(--sidenav-link-text-colour);
  flex-shrink: 0;

  .sidenav__content {
    display: flex;
    flex-direction: row;
    flex: 1;
    @import './Sections.scss';
    @import './ViewList.scss';
  }
  

  @import './Logo.scss';
  @import './Link.scss';
  @import './Signin.scss';
  @import './Signout.scss';
  @import './User.scss';
}

.sidenav.expanded {
  width: var(--sidenav-expanded-width);
  
  @import './expanded/LinkExpanded.scss';
  @import './expanded/LogoExpanded.scss';
  @import './expanded/UserExpanded.scss';
  @import './expanded/SignoutExpanded.scss';
}
