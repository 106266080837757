.themechanger {
  width: 120px;
  height: 36px;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 18px;
  border: 1px solid var(--themechanger-foreground-colour);
  justify-content: space-around;
  font-size: 22px;
  color: var(--themechanger-foreground-colour);
  margin-right: 18px;

  @import './ThemeVariant.scss';
}