.sub-content.contact-us {
  display: flex;
  align-items: normal;
  flex-direction: column;
  flex-grow: 1;
  white-space: normal;
  max-width: 1000px;
  min-width: 500px;

  .title {
    text-align: left;
    font: normal normal 600 18px/22px Segoe UI;
    letter-spacing: 0;
    color: #0b1b40;
    opacity: 1;
  }

  .text-doc {
    color: var(--secondary-background-colour);
    background-color: var(--tertiary-background-colour);
    margin: 0 30px 0 30px;
    border-radius: 2px;
    box-shadow: 0 3px 6px #00000029;
    padding: 12px 30px;
    height: 100%;

  }
}
