.app-content.home {
  align-items: normal;

  .herobanner {
    display: flex;
    flex-direction: row;
    background: linear-gradient(#002149, #021E41);
    color: white;
    flex-shrink: 0;
    justify-content: space-between;
    
    .spiel {
      margin-bottom: 50px;
      margin-left: 100px;
      margin-top: 50px;
      margin-right: 25px;
      max-width: 733px;

      .title {
        font-size: 36px;
        font-weight: 600;
        margin: 0;
      }

      .description {
        margin-top: 20px;
        font-size: 20px;
      }

      .buttons {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top: 60px;

        .get-started {
          align-items: center;
          text-decoration: none;
          display: flex;
          width: 124px;
          height: 43px;
          font-size: 15px;
          font-weight: bold;
          border: 1px solid var(--nine-background-colour);
          border-radius: 3px;
          justify-content: center;
          background-color: var(--tertiary-background-colour);
          color: var(--quinary-foreground-colour);
        }

        .watchvideo {
          margin-left: 50px;
          color: var(--tertiary-background-colour);
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .icon {
            font-size: 20px;
          }

          .watchvideo__text {
            font-size: 15px;
            font-weight: bold;
            margin-left: 15px;
          }
        }

      }
    }

    .bannervideo {
      width: 733px;
    }

    @media screen and (max-width: 1550px) {
      .bannervideo {
        display: none;
      }
    }
  }

  .news-items {
    background-color: #effcff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;

    .news-item {
      margin-left: 50px;
      margin-top: 50px;
      width: 476px;
      height: 300px;
      border-radius: 6px;
      border: 2px solid var(--primary-background-colour);
      background-color: var(--primary-background-colour);
      color: var(--nav-text-colour);
      display: flex;
      flex-direction: column;

      .news-item__overlay {
        // Try to overlap the parent border
        margin: -2px;
        // While still curving around the same parts
        border-radius: 7px;
        padding: 2px;
        display: flex;
        flex-direction: column;
        background-color: var(--eight-background-colour);
        color: var(--quarternary-foreground-colour);
        flex-grow: 1;

        .news-teaser {
          margin: 15px;
          font-weight: bold;
        }

        .news-see-more {
          text-decoration: none;
          color: var(--quarternary-foreground-colour);
          margin: auto 15px 15px auto;
          font-weight: bold;
        }
      }

      .news-title {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--eight-background-colour);
        border-radius: 0 0 7px 7px;
        margin-top: auto;

        .title-text {
          font-size: 15px;
          color: var(--quarternary-foreground-colour);
          font-weight: bold;
        }
      }
    }

    .news-item:nth-child(1) {
      background-image: url('../../../img/Roames Analytics.png');
    }

    .news-item:nth-child(2) {
      background-image: url('../../../img/Roames World.png');
    }

    .news-item:nth-child(3) {
      background-image: url('../../../img/App Hub.png');
    }

    .news-item:last-child {
      margin-bottom: 50px;
    }
  }

  .video {
    max-height: 850px;
    background-color: black;
    flex-shrink: 0;
  }
}
