.sections {
  border: solid var(--senary-foreground-colour);
  border-width: 1px 1px 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50px;

  .section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 1.25rem;
    border-bottom: 1px solid var(--senary-foreground-colour);
    padding: 0 11px;
  }

  .section.section--selected {
    background-color: var(--sidenav-link-background-active-colour);
    color: var(--sidenav-link-text-active-colour);
  }
}