@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?lpvun7');
  src:  url('fonts/icomoon.eot?lpvun7#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?lpvun7') format('truetype'),
    url('fonts/icomoon.woff?lpvun7') format('woff'),
    url('fonts/icomoon.svg?lpvun7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-picture_as_pdf:before {
  content: "\e93f";
}
.icon-local_printshop:before {
  content: "\e940";
}
.icon-x:before {
  content: "\e93e";
}
.icon-contact_mail:before {
  content: "\e933";
}
.icon-file_download:before {
  content: "\e938";
}
.icon-local_grocery_store:before {
  content: "\e934";
}
.icon-ondemand_video1:before {
  content: "\e935";
}
.icon-assignment:before {
  content: "\e939";
}
.icon-launch:before {
  content: "\e93a";
}
.icon-flight_takeoff:before {
  content: "\e936";
}
.icon-logout:before {
  content: "\e92d";
}
.icon-menu_book:before {
  content: "\e937";
}
.icon-login:before {
  content: "\e932";
}
.icon-remove_red_eye:before {
  content: "\e92c";
}
.icon-star_outline:before {
  content: "\e90e";
}
.icon-add_circle_outline:before {
  content: "\e90f";
}
.icon-swap_vertical_circle:before {
  content: "\e91e";
}
.icon-chrome_reader_mode:before {
  content: "\e91f";
}
.icon-info_outline:before {
  content: "\e920";
}
.icon-business:before {
  content: "\e921";
}
.icon-nature_people:before {
  content: "\e922";
}
.icon-error:before {
  content: "\e905";
}
.icon-chat:before {
  content: "\e906";
}
.icon-mail_outline:before {
  content: "\e907";
}
.icon-add:before {
  content: "\e908";
}
.icon-remove:before {
  content: "\e909";
}
.icon-brightness_low:before {
  content: "\e90a";
}
.icon-gps_fixed:before {
  content: "\e90b";
}
.icon-insert_chart:before {
  content: "\e90c";
}
.icon-cloud_download:before {
  content: "\e90d";
}
.icon-keyboard_return:before {
  content: "\e923";
}
.icon-brightness_3:before {
  content: "\e910";
}
.icon-brightness_4:before {
  content: "\e911";
}
.icon-grid_on:before {
  content: "\e912";
}
.icon-navigate_before:before {
  content: "\e913";
}
.icon-navigate_next:before {
  content: "\e914";
}
.icon-local_shipping:before {
  content: "\e915";
}
.icon-apps:before {
  content: "\e916";
}
.icon-check:before {
  content: "\e917";
}
.icon-ondemand_video:before {
  content: "\e918";
}
.icon-star:before {
  content: "\e924";
}
.icon-home:before {
  content: "\e919";
}
.icon-hourglass_full:before {
  content: "\e91a";
}
.icon-search:before {
  content: "\e91b";
}
.icon-stars:before {
  content: "\e91c";
}
.icon-contact_support:before {
  content: "\e91d";
}
.icon-add_circle_outline1:before {
  content: "\e925";
}
.icon-Icon_Analytics:before {
  content: "\e93b";
}
.icon-Icon_AppHub:before {
  content: "\e93c";
}
.icon-Icon_RW:before {
  content: "\e93d";
}
.icon-arrow:before {
  content: "\e928";
}
.icon-maths:before {
  content: "\e926";
}
.icon-parameters:before {
  content: "\e927";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-eye-icon:before {
  content: "\e931";
}
.icon-rw-nav-mode-enabled:before {
  content: "\e92f";
}
.icon-rw-nav-mode-disabled:before {
  content: "\e930";
}
.icon-exchange:before {
  content: "\e929";
}
.icon-explode:before {
  content: "\e92a";
}
.icon-power_tower:before {
  content: "\e92b";
}
.icon-roames_world:before {
  content: "\e902";
}
.icon-sort_descending:before {
  content: "\e903";
}
.icon-sort_ascending:before {
  content: "\e904";
}
.icon-fugro_f:before {
  content: "\e92e";
}
.icon-down_arrow:before {
  content: "\e900";
}
.icon-up_arrow:before {
  content: "\e901";
}
