body {
  display: block;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
}

p {
  text-align: justify;
}

.insights {

  #root {
    display: block;
    width: 100%;

    // Config
    @import './config/ZIndices.scss';
    @import './config/Animations.scss';
    @import './config/Colours.scss';
    @import './config/Sizes.scss';

    // Content
    @import './components/App.scss';
  }
}
