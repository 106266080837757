.signout, .signin {
  justify-content: center;
  margin-top: auto;
  height: 50px;
  color: var(--sidenav-link-text-colour);
  transition: var(--colors-transition);
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;

  .signout__icon, .signin__icon {
    font-size: 25px;
  }

  .signout__text, .signin__text {
    font-size: 14px;
  }
}
