.sidebar {
  display: block;

  .sidebar__flexarea {
    display: flex;
    flex-direction: row;
    height: 100%;

    .sidebar__draghandle {
      display: block;
      min-width: 10px;
      height: 100%;
      background-color: #cadbee;
    }
  }
}

.sidebar--contracted {
  max-width: 25px;
};

// .sidebar--expanded {};