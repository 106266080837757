.logo {
  display: flex;
  justify-content: center;
  .logo__logo {
    flex-grow: 1;
    .innerlogo {
      width: 91px;
      flex-grow: 0;
      flex-shrink: 0;
      display: block;
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 91.2 40.9" style="enable-background:new 0 0 91.2 40.9">\
        <path fill="white" d="M41.4,18c-1.1-0.1-1.9-0.9-2-2V8.6c0.1-1.1,0.9-1.9,2-2h11c1.1,0.1,1.9,0.9,2,2v1.6h-5.9c0,0,0-1.1-1.6-1.1s-1.6,1.2-1.6,1.2v4.1c0,0,0,1.3,1.7,1.3c0.8,0.1,1.6-0.5,1.7-1.3c0,0,0,0,0,0l0,0c0,0,0-0.1,0-0.1v-0.8H47v-2h7.5V18h-5.4l-0.6-1c-0.3,0.5-0.9,0.9-1.5,1L41.4,18z"/>\
        <path fill="white" d="M57.1,18V6.6h14.5c0,0,2.3,0.2,2.3,2.1v2.7c0,1.9-2.3,2.1-2.3,2.1h-1.1L74,18h-7.4l-3.4-4v4H57.1z M65.9,12.1c0.9-0.3,1.4-1.2,1.1-2c-0.2-0.5-0.6-1-1.1-1.1h-2.8v3.2H65.9z"/>\
        <path fill="white" d="M91.2,15.9c-0.1,1.1-0.9,1.9-2,2l-0.5,0H78.3c-1.1-0.1-1.9-0.9-2-2V8.6c0.1-1.1,0.9-1.9,2-2h10.9c1.1,0.1,2,0.9,2,2L91.2,15.9z M85.5,10.5c0-0.8-0.7-1.5-1.6-1.4h-0.1c-0.9,0-1.6,0.6-1.6,1.5v3.9c0,0,0,1.3,1.7,1.3c0.8,0.1,1.5-0.5,1.6-1.3c0,0,0,0,0,0l0,0c0,0,0-0.1,0-0.1L85.5,10.5z"/>\
        <path fill="white" d="M13.3,0h14v3.5H18c-0.4,0-0.7,0.3-0.7,0.7v2.4h10.1v7.6c0,0,0.1,1.6,1.5,1.6s1.5-1.6,1.5-1.6V6.6h5.9l0,9.4c-0.1,1.1-0.9,2-2,2H23.4c-1.1-0.1-1.9-0.9-2-2V9.2h-4v26.5l-3,5.2l-3-5.2V9.2H0V6.6h11.3V2c0-1.1,0.9-1.9,1.9-2"/>\
      </svg>');
    }
  }
}
