.release-notes {
    width: 95%;

    .switcher {
        width: 215px;
        margin-bottom: 15px;
    }
    
    .markdown-body {
        flex-grow: 1;
        background-color: var(--tertiary-background-colour);
        margin: 0px !important;
    }
}