.insights-app,
.default-colours {
  // Background Colours
  --primary-background-colour: #0F2C4E;
  --secondary-background-colour: #011E41;
  --tertiary-background-colour: #FFFFFF;
  --quarternary-background-colour: #CFCFCF;
  --quinary-background-colour: #F1EFEF;
  --senary-background-colour: #888888;
  --eight-background-colour: rgba(11, 27, 64, 0.7);
  --nine-background-colour: rgba(11, 27, 64, 1);

  // Foreground Colours
  --primary-foreground-colour: rgba(255, 255, 255, 0.6);
  --secondary-foreground-colour: #FFAA48;
  --tertiary-foreground-colour: #A4A4A4;
  --quarternary-foreground-colour: #FFFFFF;
  --quinary-foreground-colour: var(--secondary-background-colour);
  --senary-foreground-colour: #65788D;

  // Backgrounds
  --sidenav-background-colour: var(--primary-background-colour);

  --sidenav__logo-background-colour: var(--secondary-background-colour);
  --sidenav-link-background-active-colour: var(--secondary-background-colour);
  --themevariant--selected-background-colour: var(--secondary-background-colour);

  --home-buttons-colour: var(--tertiary-background-colour);
  --nav-background-colour: var(--tertiary-background-colour);
  --footer-background-colour: var(--tertiary-background-colour);
  --nav-toggle-background-colour: var(--tertiary-background-colour);
  --appbody-background-colour: var(--quinary-background-colour);

  --spacer-background-colour: var(--quarternary-background-colour);

  // Foregrounds
  --sidenav-user-text-colour: var(--quarternary-foreground-colour);
  --sidenav-link-text-colour: var(--primary-foreground-colour);

  --nav-icon-text-active-colour: var(--secondary-foreground-colour);
  --sidenav-link-text-active-colour: var(--secondary-foreground-colour);

  --nav-icon-text-colour: var(--tertiary-foreground-colour);
  --nav-toggle-icon-text-colour: var(--tertiary-foreground-colour);

  --sidenav-link-text-hover-colour: var(--quarternary-foreground-colour);

  --themechanger-foreground-colour: var(--quinary-foreground-colour);
  --nav-text-colour: var(--quinary-foreground-colour);
  --themevariant--selected-foreground-colour: var(--quarternary-foreground-colour);
  --content-text-foreground-colour: var(--quinary-foreground-colour);
}

@import './DarkColours.scss';
@import './LightColours.scss';