.link {
  color: var(--nav-icon-text-colour);
  transition: var(--colors-transition);
  margin-right: 15px;
  text-decoration: none;
}

.link:hover {
  color: var(--nav-icon-text-active-colour)
}
