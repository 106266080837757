.app-content.applications {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .applications {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .applicationitem {
      color: var(--secondary-background-colour);
      background-color: var(--tertiary-background-colour);
      margin: 40px 0 0 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 3px 6px #00000029;
      width: 458px;
      height: 420px;
      border-style: solid;
      border-radius: 14px;
      border-width: 7px;
      border-color: var(--tertiary-background-colour);
      transition: border 300ms ease-out;
      -webkit-transition: border box-shadow 300ms ease-out;
      -moz-transition: border 300ms ease-out;
      -o-transition: border 300ms ease-out;

      &.applicationitem--active {
        border-color: var(--secondary-background-colour);
      }

      .image {
        width: 100%;
        height: 239px;
        text-decoration: none;
        border-radius: 7px 7px 0px 0px;
      }

      .image.analytics {
        background-size: 100%;
        background-image: url("../../../img/Roames_Analytics_APP.png");
      }

      .image.roamesworld {
        background-size: 100%;
        background-image: url("../../../img/Analytics_APP.png");
      }

      .details {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 106px;
          height: 106px;
          margin-left: 23px;
          margin-top: -23px;
          background-color: white;
          border: 3px solid var(--secondary-background-colour);
          box-shadow: 0 0 0 2px var(--secondary-background-colour);
          border-radius: 8px;

          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--secondary-background-colour);
            color: white;
            border-radius: 4px;
            font-size: 55px;
            width: 100px;
            height: 100px;
            text-decoration: none;
          }
        }

        .namelinks {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 28px;

          .name {
            display: flex;
            flex-direction: row;
            font-weight: bold;
            font-size: 20px;
          }

          .links {
            margin-top: 12px;

            .link {
              font-size: 20px;
              color: var(--tertiary-foreground-colour);
              text-decoration: none;
              text-align: center;
              margin-left: 12px;
              cursor: pointer;
            }

            .link:first-child {
              margin-left: 0;
            }

            .link:hover {
              color: var(--secondary-background-colour);
            }
          }
        }

        .description {
          font-size: 14px;
          margin: 23px 23px 10px 23px;
        }
      }
    }

    .modal {
      position: fixed;
      top: 0%;
      left: 0%;
      z-index: 10;
      width: 100vw;
      height: 100vh;
      background-color: #00000080;

      &.closed {
        display: none;
      }

      .modal_content {
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 90vh;
        border-radius: 12px;
        display: flex;
        flex-direction: column;

        .title {
          display: flex;
          align-items: center;
          color: white;
          background-color: var(--secondary-background-colour);
          height: 70px;
          width: 100%;
          text-align: center;
          font: normal normal bold 26px/24px Segoe UI;
          border-radius: 12px 12px 0px 0px;
          flex: none;

          .title-text {
            width: 100%;
          }

          .close {
            margin-right: 10px;
            cursor: pointer;
          }
        }

        .product-overview {
          margin: 25px;
          padding: 5px;
          overflow-x: auto;

          .top-context {
            display: grid;
            align-items: center;
            row-gap: 5px;
            column-gap: 10px;
            grid-template-columns: 100px auto;
            grid-template-rows: auto auto;
            grid-template-areas: "application-icon application-title" "application-icon application-description";

            .icon-wrapper {
              grid-area: application-icon;
              justify-self: center;
              align-items: center;
              width: 80px;
              height: 80px;
              background-color: white;
              border: 3px solid var(--secondary-background-colour);
              box-shadow: 0 0 0 2px var(--secondary-background-colour);
              border-radius: 8px;
              display: flex;
              justify-content: center;

              .icon {
                width: 76px;
                height: 76px;
                font-size: 40px;
                text-decoration: none;
                color: white;
                background-color: var(--secondary-background-colour);
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .application-name {
              grid-area: application-title;
              align-self: flex-end;
              font: normal normal bold 20px/24px Segoe UI;
            }

            .application-description {
              grid-area: application-description;
              align-self: flex-start;
              font: normal normal normal 18px/24px Segoe UI;
            }
          }

          .product-overview-list-title {
            font-weight: bold;
          }

          .product-overview-list {
            margin: 0;
          }
        }
      }
    }
  }
}
