.top-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  width: 95%;
  color: var(--secondary-background-colour);

  .downloadlink {
    display: flex;
    flex-direction: row;
    color: var(--secondary-background-colour);
    text-decoration: none;
    align-items: center;
    width: 130px;

    .icon {
      margin-right: 20px;
    }
  }
}

.sub-content.quickstart {
  background-size: 100pt 100pt;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 600px;
  width: 90%;

  .text-doc {
    color: var(--secondary-background-colour);
    background-color: var(--tertiary-background-colour);
    padding: 30px 30px 30px 30px;
    border-radius: 2px;
    box-shadow: 0 3px 6px #00000029;
  }

  p {
    margin: 0px;
  }

  h3 {
    margin: 0px;
    margin-bottom: 10px;
  }

  h3 + img {
    margin-bottom: 10px; // match up any images with the margin of the h3
  }

  hr {
    margin: 20px 0 20px 0;
  }

  ul, ol {
    margin: 0px;
    padding-inline-start: 20px;
  }

  .imageryexample {
    width: 55px;
    height: 44px;
  }

  .imageryexample:first-child {
    margin-top: 0px;
  }

  .imageexample {
    margin: 10px 0 10px 0;
  }

  .imageicon {
    width: 21px;
  }

  .imageiconsmall {
    height: 18px;
  }

  .imagepath {
    width: 5px;
  }

  .imagelayer {
    width: 36px;
    height: 36px;
  }

  .imagepointcloud {
    width: 44px;
    height: 36px;
  }

  .keyboardkey {
    height: 28px;
    background: var(--secondary-background-colour);
    border-radius: 3px;
    color: var(--quarternary-foreground-colour);
    padding: 5px;
  }

  .sideways {
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    > * {
      margin-left: 10px;
    }

    > *:first-child {
      margin-left: 0px;
    }
  }

  .sideways.centered {
    align-items: center;    
  }

  .sideways.reversed {
    justify-content: flex-end;
    flex-direction: row-reverse;

    > * {
      margin-left: 0;
      margin-right: 10px;
    }

    > *:first-child {
      margin-right: 0px;
    }
  }

  .vertical {
    display: flex;
    flex-direction: column;
    
    > * {
      margin-top: 10px;
    }

    > *:first-child {
      margin-top: 0px;
    }
  }
}
